<template>
  <div class="d-flex flex-column flex-root">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div
      style="
        margin: 5pc auto;
        width: 30%;
        border-radius: 25px;
        padding: 5px;
        background: #fff;
      "
    >
      <div class="d-flex flex-column-auto flex-column">
        <a href="#" class="text-center mt-5">
          <img src="media/logos/choice-logo2.png" class="max-h-150px" alt="" />
        </a>
        <!-- <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg">
        <span style="color: #1b5d77">CHOICE </span
        ><span style="color: #f69463"> HOMECARE</span>
      </h3> -->
      </div>
      <transition name="fade-in-up">
        <router-view />
      </transition>
      <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
      <KTScrollTop></KTScrollTop>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
// import KTHeader from "@/view/layout/header/Header.vue";
import CTHeader from "@/view/layout/client/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
// import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
// import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  //   KTHeader,   KTFooter,  KTSubheader, KTStickyToolbar,
  components: {
    KTAside,
    CTHeader,
    KTHeaderMobile,

    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    // if (!this.GetIsUserAuthenticated) {
    //   this.$router.push({ name: "login" }).catch(()=>{});
    // }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 200);
  },
  methods: {},
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "GetIsUserAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
